import {validateEmail} from "../../utils/validation"
import {checkUniqMail, getCategory} from "../api/apiAction"
import {lmic} from "../../utils/lmic";
import {validTab} from "../tabs/tabsAction"

const countries = require("i18n-iso-countries")
countries.registerLocale(require("i18n-iso-countries/langs/en.json"))
const countriesName = countries.getNames("en")

export const
    CHANGE_PROPERTY     = "CHANGE_PROPERTY",
    SET_ERROR           = "SET_ERROR",
    SUBMIT_INFORMATION  = "SUBMIT_INFORMATION",
    SELECT_TICKET       = "SELECT_TICKET",
    SELECT_OPTIONS      = "SELECT_OPTIONS",
    RESET_OPTIONS       = "RESET_OPTIONS",
    CHANGE_INFORMATION  = "CHANGE_INFORMATION",
    CHANGE_DOCUMENTS    = "CHANGE_DOCUMENTS",
    SUBMIT_DOCUMENTS    = "SUBMIT_DOCUMENTS",
    SUBMIT_STRIPE_ERROR = "SUBMIT_STRIPE_ERROR",
    SUBMIT_STRIPE_SUCCESS   = "SUBMIT_STRIPE_SUCCESS",
    RESET_STRIPE        = "RESET_STRIPE",
    RESET_REGISTRATION  = "RESET_REGISTRATION",
    DELETE_FILE         = "DELETE_FILE"


export const onChange = (e, checkbox = false) => dispatch => {
    const {name}    = e.target
    const value     = checkbox ? e.target.checked : e.target.value

    dispatch({
        type: CHANGE_PROPERTY,
        name: name,
        value: value,
    });
};

export const onChangeByName = (name, value) => dispatch => {
    dispatch({
        type: CHANGE_PROPERTY,
        name: name,
        value: value,
    });
};

export const setError = (name, error) => dispatch => {
    dispatch({
        type: SET_ERROR,
        name: name,
        error: error,
    });
};

const determinedCategory = (form, categories, categoryParent) => async dispatch => {
    let category = null

    if(form.professionalCtgSelect.value === "Nurse") {
        category = categories.find(category => category.categories_name === "Nurse" && category.category_parent === categoryParent.idcategories)
    }
    else if(form.professionalCtgSelect.value === "Student") {
        category = categories.find(category => category.categories_name === "Student" && category.category_parent === categoryParent.idcategories)
    }
    else if(form.registered_under.value === true) {
        category = categories.find(category => category.categories_name === "Young Professional" && category.category_parent === categoryParent.idcategories)
    }
    else if(lmic.find(country => country === form.registered_country.value) !== undefined) {
        category = categories.find(category => category.categories_name === "LMI Country" && category.category_parent === categoryParent.idcategories)
    }
    else {
        category = categories.find(category => category.idcategories === categoryParent.idcategories)
    }
    await dispatch(getCategory(category !== undefined && category !== null ? category.idcategories : null, false))
}

export const selectCategory = (categories, value = null) => async dispatch => {
    const category = categories.find(category => category.categories_name === value)

    await dispatch(getCategory(category !== undefined && category !== null ? category.idcategories : null))
}

export const submitInformation = (user, form, categories, categoryParent, callback) => async dispatch => {
    const names         = Object.getOwnPropertyNames(form)
    const properties    = Object.values(form)
    let valid           = true

    await properties.forEach(async (property, i) => {
        if(names[i] !== "registered_interest" && names[i] !== "registered_intouch" && names[i] !== "registered_cvg") {
            if(property.required && property.value.length <= 0) {
                dispatch(setError(names[i], "This field is required"))
                valid = false
            }
            else if((names[i] === "registered_mail" || names[i] === "confirmEmail") && !validateEmail(property.value)) {
                dispatch(setError(names[i], "Enter a valid email address"))
                valid = false
            }
            else {
                dispatch(setError(names[i], ""))
            }
        }
    })

    if(user === null) {
        await dispatch(checkUniqMail(form.registered_mail.value))
            .then(res => {
                if(res === false) {
                    dispatch(setError("registered_mail", "An account already exist with this mail"))
                    valid = false
                }
            })
    }

    if(form.registered_mail.value !== form.confirmEmail.value) {
        dispatch(setError("confirmEmail", "Email addresses are not the same"))
        valid = false
    }

    if(valid === true) {
        await dispatch(determinedCategory(form, categories, categoryParent)).then(() => callback())
        dispatch(validTab("ticketTab"))
    }

    dispatch({
        type: SUBMIT_INFORMATION,
        valid: valid
    })
}

export const selectTicket = (ticket) => dispatch => {
    dispatch({
        type: SELECT_TICKET,
        ticket: ticket,
    });
};

export const selectOptions = (option, add = true) => dispatch => {
    dispatch({
        type: SELECT_OPTIONS,
        option: option,
        add: add,
    });
};

export const userToForm = (user) => dispatch => {
    Object.keys(user.registered).forEach(property => dispatch(onChangeByName(property, getValue(property, user.registered[property]))));

    dispatch(onChangeByName("professionalCtgSelect", getValue("professionalCtgSelect", user.registered.profession_category)))
    dispatch(onChangeByName("confirmEmail", user.registered.registered_mail))
}

export const getValue = (name, value) => {
    if(name === "registered_under") {
        return value === null ? false : value
    }
    else if(name === "registered_gender") {
        return value === null || value === "" ? "Mr" : value
    }
    else if(name === "gender") {
        return value === null || value === "" ? "Male" : value
    }
    else if(name === "registered_profession_category") {
        return value === null || value === "" ? "Physician" : value
    }
    else
        return value === null ? "" : value
}

export const onChangeInformation = (e, isInterest = true) => dispatch => {
    const {name} = e.target

    dispatch({
        type: CHANGE_INFORMATION,
        value: name,
        isInterest: isInterest
    });
}


export const onChangeDocuments = (name, value) => dispatch => {
    dispatch({
        type: CHANGE_DOCUMENTS,
        name: name,
        value: value,
    });
};

export const submitDocuments = (user, form, callback) => async dispatch => {
    const names         = Object.getOwnPropertyNames(form)
    const properties    = Object.values(form)
    let valid           = true

    await properties.forEach(async (property, i) => {
        if(property.required && property.value.length <= 0) {
            dispatch(setError(names[i], "This field is required"))
            valid = false
        }
        else {
            dispatch(setError(names[i], ""))
        }
    })

    if(valid === true) {
        dispatch(validTab("documentsTab"))
        callback()
    }

    dispatch({
        type: SUBMIT_DOCUMENTS,
        valid: valid
    })
}

export const userToDocuments = (user) => dispatch => {
    Object.keys(user.registered).forEach(property => dispatch(onChangeDocuments(property, getValue(property, user.registered[property]))));
}

export const submitStripe = (result) => dispatch => {
    if(result.error) {
        dispatch({
            type: SUBMIT_STRIPE_ERROR,
            error: result.error.message,
        })
    }
    else {
        if(result.paymentIntent.status === 'succeeded')
            dispatch({
                type: SUBMIT_STRIPE_SUCCESS,
            })
    }
}

export const resetStripe = () => dispatch => {
    dispatch({
        type: RESET_STRIPE
    })
}

export const resetRegistration = () => dispatch => {
    dispatch({
        type: RESET_REGISTRATION
    })
}

export const deleteFile = () => dispatch => {
    dispatch({
        type: DELETE_FILE
    })
}

export const memberToForm = (member) => dispatch => {
    Object.keys(member).forEach(property => dispatch(onChangeByName(property, getValue(property, member[property]))))
    dispatch(onChangeByName("confirmEmail", member.registered_mail))
    
    dispatch(onChangeByName("countryCode", member.registered_country))
    dispatch(onChangeByName("registered_country", countries.getName(member.registered_country, "en")))
}
