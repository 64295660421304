export const formatBytes = (a,b) => {
    if(0 === a)
        return"0 Bytes"

    const c = 1024,
        d = b || 8,
        e = ["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"],
        f = Math.floor(Math.log(a) / Math.log(c))

    return parseFloat((a / Math.pow(c,f)).toFixed(d)) + " " + e[f]
}

export const isDefined = data => data !== undefined ? data !== null : false
