import {
    ERROR,
    GET_CATEGORIES,
    GET_CATEGORY,
    GET_OPTIONS,
    GET_USER,
    LOGIN,
    LOGIN_FAILED,
    LOGIN_MEMBER,
    LOGIN_WCTOH
} from "./apiAction"
import {RESET_REGISTRATION} from "../registration/registrationAction";

const initialState = {
    error: "",
    login_failed: null,
    categories: [],
    category: null,
    optionsAPI: [],
    idregistered: null,
    user: null,
    member: null,
    wctoh: null,
}

const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
            }
        case GET_CATEGORY:
            return action.isParent === true ?
                {
                    ...state,
                    category: action.category,
                }
                : state
        case GET_OPTIONS:
            return {
                ...state,
                optionsAPI: action.options
            }
        case ERROR:
            return {
                ...state,
                error: action.error,
            }
        case LOGIN:
            return {
                ...state,
                idregistered: action.id,
                login_failed: null,
            }
        case LOGIN_FAILED:
            return {
                ...state,
                login_failed: action.error
            }
        case GET_USER:
            return {
                ...state,
                user: action.user,
            }
        case RESET_REGISTRATION:
            return initialState
        case LOGIN_MEMBER:
            return {
                ...state,
                member: action.member
            }
        case LOGIN_WCTOH:
            return {
                ...state,
                wctoh: action.wctoh
            }
        default:
            return state
    }
}

export default apiReducer
